<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>

      <v-card-text>
        <v-form :disabled="notEdit">
          <v-row>
            <v-col cols="6">
              <app-text-field
                :disabled="form.auto_name"
                v-model="form.name"
                label="Nome"
              />
            </v-col>
            <v-col>
              <app-text-field disabled v-model="form.number" label="Número" />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex justify-space-between" cols="8">
              <v-checkbox v-model="form.auto_name" label="Gerar Nome" />
              <v-checkbox
                v-model="form.update_webhook"
                label="Atualizar Webhook"
              />
              <v-checkbox v-model="form.is_default" label="Padrão" />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <app-text-field
                v-model="form.instance_id"
                label="ID da Instância"
              />
            </v-col>

            <v-col>
              <app-text-field v-model="form.token" label="Token" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <app-select v-model="form.type" label="Tipo" :items="types" />
            </v-col>
            <v-col>
              <app-select
                v-model="form.location"
                label="Localização"
                :items="locations"
              />
            </v-col>
            <v-col>
              <app-select
                v-model="form.driver"
                label="Driver"
                :items="drivers"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="dialog = false" text> Cancelar </v-btn>
        <v-btn @click="handleSave()" class="mr-2" color="primary">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      form: {},
      baseForm: {
        name: "Instância",
        driver: null,
        number: "OFF",
        instance_id: null,
        token: null,
        auto_name: false,
        update_webhook: false,
      },

      drivers: [
        { text: "Z-API", value: "\\App\\Services\\ZApi" },
        { text: "Evolution API", value: "\\App\\Services\\EvolutionApi" },
        { text: "ApiZap", value: "\\App\\Services\\ApiZap" },
      ],

      types: [
        { text: "Cobrança", value: "billing" },
        { text: "Sessão", value: "session" },
        { text: "Company", value: "company" },
      ],

      locations: [
        { text: "Rio", value: "Rio" },
        { text: "Mantena", value: "Mantena" },
        { text: "Cliente", value: "Cliente" },
      ],
    };
  },

  computed: {
    isInsert() {
      return this.form.id == null;
    },
    title() {
      return this.isInsert ? "Cadastrar Instância" : "Editar Instância";
    },

    isEvolution() {
      return this.form.driver == "\\App\\Services\\EvolutionApi";
    },
    notEdit() {
      return (
        this.isEvolution &&
        this.form.type == "company" &&
        this.form.company_id != null
      );
    },
  },

  mounted() {},

  methods: {
    open(id) {
      this.reset();

      if (id) {
        this.show(id);
      }

      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    show(id) {
      this.$loading.start();
      this.$http
        .show("admin/whatsapp-instances", id)
        .then((response) => {
          this.form = JSON.parse(JSON.stringify(response.whatsapp_instance));
          this.form.number = response.info.number;
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    handleSave() {
      if (this.isInsert) {
        this.store();
      } else {
        this.update();
      }
    },

    store() {
      this.$loading.start();
      this.$http
        .store("admin/whatsapp-instances", this.form)
        .then(() => this.processSave())
        .catch(() => this.$loading.finish());
    },

    update() {
      this.$loading.start();
      this.$http
        .update("admin/whatsapp-instances", this.form.id, this.form)
        .then((response) => {
          this.processSave();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    processSave() {
      this.$loading.finish();
      this.$emit("store");
      this.dialog = false;
    },
  },
};
</script>

<style>
</style>