<template>
  <div>
    <v-row>
      <v-col>
        <app-btn @click="select()" color="primary">
          <v-icon> mdi-sync </v-icon>
        </app-btn>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn @click="handleMigrateUsers()" color="primary" class="mr-4">
          Migrar Usuários
        </v-btn>

        <v-btn color="primary" @click="handleOpenWhatsappInstance((id = null))">
          <app-icon>add</app-icon>
          <span class="d-md-inline d-none">Adicionar</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-chip-group @change="select()" v-model="query.type">
          <v-chip filter color="primary" value="system"> Sistema </v-chip>
          <v-chip filter color="primary" value="company"> Company </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="whatsappInstances"
          :items-per-page="-1"
          dense
          disable-sort
          hide-default-footer
          @click:row="handleOpenWhatsappInstance($event.id)"
        >
          <template v-slot:[`item.name`]="{ item }">
            <v-icon v-if="item.is_default" color="primary" small>
              mdi-pin
            </v-icon>
            <span v-text="item.name" />
          </template>

          <template v-slot:[`item.number`]="{ item }">
            <span v-if="item.info.number">
              {{ $format.phone(item.info.number) }}
            </span>
            <span v-if="item.info.message">
              {{ item.info.message }}
            </span>
          </template>
          <template v-slot:[`item.instance_id`]="{ item }">
            {{ item.instance_id }}
          </template>

          <template v-slot:[`item.driver`]="{ item }">
            {{ drivers[item.driver] }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              :color="status[item.info.status].color"
              v-text="status[item.info.status].text"
              dark
              small
            />
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" v-bind="attrs" v-on="on">
                  mdi-dots-vertical
                </v-icon>
              </template>

              <v-list>
                <v-list-item @click.stop="resendNotification(item.id)">
                  <v-list-item-title>Reenviar</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <StoreWhatsAppInstanceForm
      ref="StoreWhatsAppInstanceForm"
      @store="select()"
    />
    <MigrateUsersWhatsappInstance
      @store="select()"
      ref="MigrateUsersWhatsappInstance"
    />
  </div>
</template>

<script>
import StoreWhatsAppInstanceForm from "@/components/admin/forms/StoreWhatsAppInstanceForm";

import MigrateUsersWhatsappInstance from "@/components/admin/forms/MigrateUsersWhatsappInstance.vue";

export default {
  components: {
    StoreWhatsAppInstanceForm,
    MigrateUsersWhatsappInstance,
  },

  data() {
    return {
      whatsappInstances: [],
      headers: [
        { text: "ID", value: "id", align: "center" },
        { text: "Nome", value: "name", align: "center" },
        { text: "Numero", value: "number", align: "center" },
        { text: "Instância ID", value: "instance_id", align: "center" },
        { text: "Driver", value: "driver", align: "center" },
        { text: "Empresas", value: "companies", align: "center" },
        { text: "Status", value: "status", align: "center" },
        { text: "", value: "actions", align: "center" },
      ],

      drivers: {
        "\\App\\Services\\ZApi": "Z-API",
        "\\App\\Services\\EvolutionApi": "Evolution API",
        "\\App\\Services\\ApiZap": "ApiZap",
      },

      status: {
        offline: { color: "red", text: "Offline" },
        online: { color: "green", text: "Online" },
      },
      types: {
        session: "Sessão",
        billing: "Cobrança",
        company: "Empresa",
      },
      query: {
        type: "system",
      },
    };
  },

  mounted() {
    this.select();
  },

  methods: {
    select() {
      this.$loading.start();
      this.$http
        .index("admin/whatsapp-instances", this.query)
        .then((response) => {
          this.whatsappInstances = response.whatsapp_instances;
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    handleOpenWhatsappInstance(id) {
      this.$refs.StoreWhatsAppInstanceForm.open(id);
    },

    handleMigrateUsers() {
      this.$refs.MigrateUsersWhatsappInstance.open();
    },

    resendNotification(id) {
      this.$loading.start();
      this.$http
        .update("admin/whatsapp-instances/resend-reminders-session", id)
        .then((response) => this.$loading.finish())
        .catch((error) => this.$loading.finish());
    },
  },
};
</script>

<style>
</style>