var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('app-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.select()}}},[_c('v-icon',[_vm._v(" mdi-sync ")])],1)],1),_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary"},on:{"click":function($event){return _vm.handleMigrateUsers()}}},[_vm._v(" Migrar Usuários ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.handleOpenWhatsappInstance((_vm.id = null))}}},[_c('app-icon',[_vm._v("add")]),_c('span',{staticClass:"d-md-inline d-none"},[_vm._v("Adicionar")])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-chip-group',{on:{"change":function($event){return _vm.select()}},model:{value:(_vm.query.type),callback:function ($$v) {_vm.$set(_vm.query, "type", $$v)},expression:"query.type"}},[_c('v-chip',{attrs:{"filter":"","color":"primary","value":"system"}},[_vm._v(" Sistema ")]),_c('v-chip',{attrs:{"filter":"","color":"primary","value":"company"}},[_vm._v(" Company ")])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.whatsappInstances,"items-per-page":-1,"dense":"","disable-sort":"","hide-default-footer":""},on:{"click:row":function($event){return _vm.handleOpenWhatsappInstance($event.id)}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.is_default)?_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" mdi-pin ")]):_vm._e(),_c('span',{domProps:{"textContent":_vm._s(item.name)}})]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [(item.info.number)?_c('span',[_vm._v(" "+_vm._s(_vm.$format.phone(item.info.number))+" ")]):_vm._e(),(item.info.message)?_c('span',[_vm._v(" "+_vm._s(item.info.message)+" ")]):_vm._e()]}},{key:"item.instance_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.instance_id)+" ")]}},{key:"item.driver",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.drivers[item.driver])+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.status[item.info.status].color,"dark":"","small":""},domProps:{"textContent":_vm._s(_vm.status[item.info.status].text)}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.resendNotification(item.id)}}},[_c('v-list-item-title',[_vm._v("Reenviar")])],1)],1)],1)]}}],null,true)})],1)],1),_c('StoreWhatsAppInstanceForm',{ref:"StoreWhatsAppInstanceForm",on:{"store":function($event){return _vm.select()}}}),_c('MigrateUsersWhatsappInstance',{ref:"MigrateUsersWhatsappInstance",on:{"store":function($event){return _vm.select()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }